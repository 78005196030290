import {
    DECREMENT_WATCHLIST_COUNTER,
    SET_RECENT_BOOKS_COUNT_DATA,
    SET_IS_SCANNING,
    SET_WATCHLIST,
    RESET_WATCHLIST_DATA
} from "./actionTypes";
import { BUY_STATE, SELL_STATE } from "common/constants/searchTypes";

export const initState = {
    watchlistCounter: 0,
    watchlistData: {
        [SELL_STATE]: [],
        [BUY_STATE]: []
    },
    isWatchlistLoading: true,
    isScanning: false,
    recentBooksCount: 0,
    recentBookFirstNotEmptyTabType: SELL_STATE
};

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_WATCHLIST:
            return {
                ...state,
                isWatchlistLoading: false,
                watchlistCounter: payload.total,
                watchlistData: {
                    [SELL_STATE]: payload.data.filter((item) => item.type === SELL_STATE),
                    [BUY_STATE]: payload.data.filter((item) => item.type === BUY_STATE)
                }
            };
        case DECREMENT_WATCHLIST_COUNTER:
            return { ...state, watchlistCounter: state.watchlistCounter - 1 };
        case RESET_WATCHLIST_DATA:
            return {
                ...state,
                watchlistData: {
                    [SELL_STATE]: [],
                    [BUY_STATE]: []
                }
            };
        case SET_IS_SCANNING:
            return { ...state, isScanning: payload.isScanning };
        case SET_RECENT_BOOKS_COUNT_DATA:
            return {
                ...state,
                recentBooksCount: payload.count,
                recentBookFirstNotEmptyTabType: payload.type
            };
        default:
            return { ...state };
    }
};
