import http from "api";
import { addErrorNotification } from "common/notifications";

import {
    SET_WATCHLIST,
    SET_WATCHLIST_COUNTER_ERROR,
    SET_IS_SCANNING,
    SET_RECENT_BOOKS_COUNT_DATA
} from "../reducers/actionTypes";

import { getFirstNotEmptyRecentTab } from "../utils";
import { SELL_STATE } from "common/constants/searchTypes";

export const fetchWatchlist = async ({ dispatch }) => {
    try {
        const response = await http.getWatchList();

        dispatch({
            type: SET_WATCHLIST,
            payload: { data: response.data, total: response.total }
        });
    } catch (error) {
        dispatch({ type: SET_WATCHLIST_COUNTER_ERROR });

        addErrorNotification({ msg: error.message });
    }
};

export const setIsScanning = ({ dispatch, isScanning }) => {
    dispatch({
        type: SET_IS_SCANNING,
        payload: { isScanning }
    });

    if (document) {
        if (isScanning) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }
};

export const initRecentBooksCount = ({ dispatch }) => {
    let recentBooksConfig = localStorage.getItem("recent");
    recentBooksConfig = recentBooksConfig ? JSON.parse(localStorage.getItem("recent")) : {};

    const totalBooks = Object.values(recentBooksConfig).flat().length;
    const tabType = getFirstNotEmptyRecentTab(recentBooksConfig);

    dispatch({
        type: SET_RECENT_BOOKS_COUNT_DATA,
        payload: { count: totalBooks, type: tabType }
    });
};

export const setRecentBooks = ({ dispatch, value, booksData }) => {
    const tabType = booksData ? getFirstNotEmptyRecentTab(booksData) : SELL_STATE;

    dispatch({
        type: SET_RECENT_BOOKS_COUNT_DATA,
        payload: { count: value, type: tabType }
    });
};
