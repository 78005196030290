import { createContext, useContext } from "react";

import { assertDefined } from "common/utils";

import { BUY_STATE, MIX_STATE, RENT_STATE, SELL_STATE } from "common/constants/searchTypes";

const tabsOrder = [SELL_STATE, BUY_STATE, RENT_STATE, MIX_STATE];

export const getFirstNotEmptyRecentTab = (recentlyBooksData) => {
    const tabType = tabsOrder.find((type) => {
        if (recentlyBooksData[type]) {
            return !!recentlyBooksData[type].length;
        }
    });

    return tabType || tabsOrder[0];
};

export const AppContext = createContext(null);

export const useAppContext = () => {
    const context = useContext(AppContext);

    assertDefined(context);

    return context;
};
